<template>
  <div class="overflow-x-hidden">
    <experiment-toggle name="CookieBanner">
      <template #a />
      <template #b>
        <CookieBannerV1 />
      </template>
      <template #c>
        <CookieBannerV2 />
      </template>
    </experiment-toggle>
    <experiment-toggle name="airLoveInTheAirCampaign">
      <template #a />
      <template #b>
        <LoveIsTheAirCampaignBanner />
      </template>
    </experiment-toggle>
    <experiment-toggle name="airSpringCampaign">
      <template #a />
      <template #b>
        <SpringCampaignBanner />
      </template>
    </experiment-toggle>
    <experiment-toggle name="mayCampaign">
      <template #a />
      <template #b>
        <UrgencyBanner />
      </template>
      <template #c>
        <UrgencyBanner background-color="bg-[#ffbd00]" />
      </template>
    </experiment-toggle>
    <Header @toggle="navToggle" />
    <main>
      <Nuxt />
    </main>
    <Footer />
    <client-only>
      <noscript>
        <iframe
        :src="`https://www.googletagmanager.com/ns.html?id=${gtmKey}&noscript=`"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
        ></iframe>
      </noscript>
    </client-only>
  </div>
</template>
<script>
document.head.insertAdjacentHTML(
  'beforeend',
  `<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
</style>`,
);
</script>

<script>
import Header from '@/components/header/Header.vue';
import Footer from '@/components/footer/Footer.vue';
import ExperimentToggle from '~/components/experiments/ExperimentToggle.vue';
import LoveIsTheAirCampaignBanner from '~/components/experiments/tr-505-love-is-the-air-campaign/LoveIsTheAirCampaign.vue';
import SpringCampaignBanner from '~/components/experiments/tr-501-spring-clean-your-car-campaign/SpringCampaign.vue';
import UrgencyBanner from '~/components/experiments/tr-717-may-campaign/UrgencyBanner.vue';
import CookieBannerV1 from '~/components/experiments/be-1101-cookie-banner/CookieBannerV1.vue';
import CookieBannerV2 from '~/components/experiments/be-1101-cookie-banner/CookieBannerV2.vue';
export default {
  head() {
    const script = [];

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
      };
      script.push(attrs);
    }

    return {
      script,
    };
  },

  computed: {
    language() {
      return this.$store.state.language;
    },
  },

  components: {
    Header,
    Footer,
    ExperimentToggle,
    LoveIsTheAirCampaignBanner,
    SpringCampaignBanner,
    UrgencyBanner,
    CookieBannerV1,
    CookieBannerV2,
  },

  data() {
    return {
      navShown: false,
      gtmKey: undefined,
    };
  },

  created() {
    const { language } = this.$store.state;
    this.gtmKey = language.gtm_key;
  },

  mounted() {
    this.loadGTM();
  },

  methods: {
    loadGTM() {
      const { gtmKey } = this;
      if (gtmKey) {
        /* eslint-disable */
        window.loadTagManager = function (w, d, s, l) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmKey}` + dl;
          f.parentNode.insertBefore(j, f);
        };
        if (typeof tagManagerDelay === 'undefined') {
          window.loadTagManager(window, document, 'script', 'dataLayer');
        }
        /* eslint-enable */
      }
    },
    navToggle(value) {
      this.navShown = value;
    },
    getCookie(name) {
      const cookie = `; ${document.cookie}`;
      const cookieParts = cookie.split(`; ${name}=`);
      if (cookieParts.length === 2) return cookieParts.pop().split(';').shift();
      return '';
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
* {
  -webkit-font-smoothing: antialiased;
}

.skew {
  transform: skew(0deg, -1deg);
}

.unskew {
  transform: skew(0deg, 1deg);
}

.skew,
.unskew {
  backface-visibility: hidden;
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
</style>
